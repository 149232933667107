import { store } from '~/plugins/vuex';
import { CATALOG_ROUTES } from '~/support/constants';

export default defineNuxtRouteMiddleware(async (to) => {
  const { user } = store.state.auth;
  const browseCatalogByFamilies = user.org.browseCatalogByFamilies;
  const name = browseCatalogByFamilies ? CATALOG_ROUTES.families : CATALOG_ROUTES.dataProducts;

  if (!to.name.startsWith(name) && !to.name.startsWith(CATALOG_ROUTES.suppliers)) {
    return await navigateTo({ name }, { replace: true });
  }
});
